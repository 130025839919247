<template>
    
    <div class="card_gallery" library="giphy" ref="galery" style="height:calc(100% - 88px)">
        <div class="gallery_column" v-for="animations in columns" :key="animations">
            <template v-for="animation in animations" :key="animation" @click.stop="selectAnimation(animation)">
                
                <template v-if="animation.soon">
                    <div class="choice_card" :class="{'soon' : true, 'coloredBackground' : animation.name == 'quote'}" >
                        <div class="commingSoon">
                            <p><i>{{ $t('availableSoon') }}</i></p>
                        </div>
                        
                        <img v-if="animation.name == 'quote'" src="../../../../../assets/quote.png" >
                        <img v-else-if="animation.name == 'speaker1'" src="../../../../../assets/speaker1.png" >
                        <img v-else-if="animation.name == 'bigtitle1'" src="../../../../../assets/bigTitle1.png" >
                        <img v-else-if="animation.name == 'social1'" src="../../../../../assets/social1.png" >
                        <img v-else-if="animation.name == 'bigTitle2'" src="../../../../../assets/bigTitle2.png" >
                        <img v-else-if="animation.name == 'number1'" src="../../../../../assets/number1.png" >
                        <p v-else>{{ animation.name }}</p>
                        
                    
                    </div>
                </template>

                <template v-else>

                    <div class="choice_card" @click.stop="selectAnimation(animation)">
            
                        <img v-if="animation.name == 'simpleText'" src="../../../../../assets/simple_text.png" >
                    </div>
                </template>
                
            </template>

        </div>
    </div>

</template>

<script setup>
//import upload from "./upload.vue"
import { defineAsyncComponent, defineProps, defineEmits, ref, reactive, onMounted, computed, onBeforeUnmount, inject } from 'vue';
import { makeColumns } from "@/components/Composables/columns.js";
import textAnimations from "./list"

const emit = defineEmits(["animationSelected"]);

const columns = computed(()=>{
    return makeColumns(animations.value)
})

const animations = ref([...textAnimations, {
        name : "quote",
        soon : true
    },
    {
        name : "speaker1",
        soon : true
    },
    {
        name : "bigtitle1",
        soon : true
    },
    {
        name : "social1",
        soon : true
    },
    {
        name : "bigTitle2",
        soon : true
    },
    {
        name : "number1",
        soon : true
    }
]);

function selectAnimation(overlayData){
    emit('animationSelected', overlayData)
}

</script>

<style lang="scss" scoped>
    .gallery_column{
        margin-top: 10px;
    }

    .choice_card{
        /*
        border: 1px solid #3eb1c881;
        padding: 42px 0 !important;
        */
        background-color: white;
        filter: drop-shadow(0px 0px 5px #00000020);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: center;
        font-weight: 500;
        border-radius: 8px;
        transition: background-color .2s, color .2s;

        & > img{
            width: 90%;
            height: unset;
            /*
            height: 90%;
            filter: drop-shadow(0px 0px 5px #00000020);
            */
            overflow: visible;
            flex-grow: 0; 
            -o-object-fit: none;
            object-fit: unset;
            transition: width 0.15s ease-in-out;
        }

        &:not(.soon):hover{
            & > img{
                width: 85%;
            }
        }

        height: 130px;

        
    }

    .choice_card:hover{
        /*
        background-color: var(--color2);
        color: white;
        */
    }

    .choice_card.soon{
        cursor: default;
        padding: 0 !important;
        
        &:hover{
            & > .commingSoon{
                opacity: 1;
            }
            & > img{
                opacity: 0;
            }
        }
    }

    .commingSoon{
        opacity: 0;
        background-color: var(--color2) !important;
        position: absolute;
        height: 100%;
        width: 100%;
        color: white !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: opacity 0.15s;
        overflow: hidden;
        border-radius: 8px;
        
    }

    .coloredBackground{
        background-color: var(--color1);
    }

</style>